import React from 'react';
import './App.css';
import Cronos from './Services/Cronos'
import Common from './Services/Common'

import TaskView from './Components/TaskView'
import MainScreen from './Components/MainScreen'

import { ReactComponent as FraxReactComponent } from './Frax'
import timeSvg from './img/time.svg'

// setup: Construye los servicios y los elementos de la aplicación y los comparte a través del singleton common
const cronos = new Cronos()
Common.cronos = cronos;

function addTask(name, log) {
    const taskId = cronos.addTask(name)
    log.forEach(item => {
        cronos.start(taskId, new Date(item[0]))
        cronos.end(taskId, item[2], new Date(item[1]))
    })
    return taskId
}


addTask('Cronos', [
    ['2019-06-30 09:00:00', '2019-06-30 12:00:00', 
        { description: 'Definicion del nombre de la aplicacion para probar la longitud del texto', type: 'Diseño' }],           //3 horas
    ['2019-07-15 09:00:00', '2019-07-15 18:00:00', 
        { description: 'Definicion del alcance', type: 'Diseño' }],           //9 horas
    ['2019-07-29 09:25:00', '2019-07-29 09:50:00', 
        { description: 'Mejoras en CSS', type: 'Programacion' }],            //25 minutos
    ['2019-07-30 13:05:00', '2019-07-30 16:05:00', 
        { description: 'Agregado de funcionalidad', type: 'Programacion' }], // 3 horas
    ['2019-08-01 09:05:00', '2019-08-01 10:05:00', 
        { description: 'Agregado de funcionalidad', type: 'Programacion' }], // 1 horas 
    ['2019-08-05 09:05:00', '2019-08-05 13:05:00', 
        { description: 'Agregado de funcionalidad', type: 'Programacion' }], // 4 horas        
     ['2019-08-05 14:05:00', '2019-08-05 15:05:00', 
        { description: 'Agregado de funcionalidad', type: 'Programacion' }], // 1 horas         
])

//al 31 de julio debo tener 3h 25 minutos este semana
//al 31 de julio debo tener 12h 25 minutos este mes
//al 31 de julio debo tener 15h 25 minutos total


//al 06 de agosto debo tener 5h 00 minutos este semana
//al 06 de agosto debo tener 6h 00 minutos este mes
//al 06 de agosto debo tener 21h 25 minutos total


addTask('Team meeting', [
    ['2019-07-29 11:50:14', '2019-07-29 12:00:00'],
])

addTask('Task one', [
    ['2019-07-29 11:20:14', '2019-07-29 11:30:55'],
])
/*
addTask('Task one two', [
    ['2019-07-29 11:50:14', '2019-07-29 12:30:55'],
])

addTask('Task one two three', [
    ['2019-07-29 11:50:14', '2019-07-29 12:30:55'],
])
*/


//cronos.start(t1)


function SplashScreen() {
    return <div className="full-screen flex-column flex-center-content">
        <h3>TASKAR v1.0.0</h3>
        <div>
            <img src={timeSvg} style={{ maxWidth: '300px', width: '175px', height: 'auto' }} alt="cronos" />
        </div>
        <span>Loading ...</span>
    </div>
}

function TaskViewPopup(props) {
    if (!props.taskId) return null

    return <TaskView taskId={props.taskId} service={cronos} ></TaskView>

}

class App extends FraxReactComponent {

    constructor(props) {
        super(props)
        this.start()
        this.state = { loading: true }
    }

    start() {
        this.setTimeout(() => {
            this.setState({ loading: false })
            this.mountEvent(cronos.onPropertyChanged, () => this.refresh())
        }, 1000)
    }

    render() {
        return <div className="full-screen">
            {this.state.loading && <SplashScreen></SplashScreen>}
            {!this.state.loading && <MainScreen></MainScreen>}
            {cronos.selectedTaskId && <TaskViewPopup taskId={cronos.selectedTaskId}></TaskViewPopup>}
        </div>
    }
}


export default App;
