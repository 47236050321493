import * as yup from "yup";
import Events from "./Events";

//const STATE_ASSIGNED = "StateAssigned";
const PROPERTY_CHANGED = Symbol(),
    state = Symbol(),
    events = Symbol(),
    addProperty = Symbol(),
    setProperty = Symbol(),
    getProperty = Symbol(),
    schema = Symbol();

export default class ServiceBase {
    [state] = {};
    [events] = new Events();
    _proxies = {}

    emitPropertyChanged = this[events].getEmiter(PROPERTY_CHANGED)
    onPropertyChanged = this.getSubscriber(PROPERTY_CHANGED);

    constructor(schema) {
        this[schema] = yup.object(schema);
        for (var k in schema) {
            this[addProperty](k, schema[k]);
        }
    }

    get state() {
        return this[state];
    }

    set state(newState) {
        this._proxies = {}
        this[state] = newState;
        this.emit(PROPERTY_CHANGED, "*");
    }

    getSubscriber(event) {
        return this[events].getSubscriber(event);
    }

    emit(event, data, timestamp) {
        this[events].emit(event, data, timestamp);
    }

    async validate() {
        return await this[schema].validate(this[state]);
    }

    // private
    [addProperty](propertyName) {
        Object.defineProperty(this, propertyName, {
            get: function () {
                return this[getProperty](propertyName);
            },
            set: function (value) {
                this[setProperty](propertyName, value);
            }
        });
        return this;
    }

    [setProperty](propertyName, value) {
        if (!Array.isArray(value) && this._proxies[propertyName]) delete this._proxies[propertyName];
        this[state][propertyName] = value;
        this[events].emit(PROPERTY_CHANGED, propertyName);
    }

    [getProperty](propertyName) {
        if (Array.isArray(this[state][propertyName])) {
            return this._arrayProxy(propertyName);
        } else {
            return this[state][propertyName];
        }
    }


    _arrayProxy(propertyName) {
        if (!this._proxies[propertyName]) {
            //console.log('set proxy for ' + propertyName)
            this._proxies[propertyName] = new Proxy(this[state][propertyName], this._arrayChangeHandler(propertyName))
        }
        return this._proxies[propertyName]
    }

    _arrayChangeHandler = arrayPropertyName => ({
        set: (target, property, value, receiver) => {
            target[property] = value;
            // you have to return true to accept the changes
            if (isNaN(property)) {
                //console.log('setting ', property, ' for ', target, ' with value ', value, ' into ', arrayPropertyName);
                this[events].emit(PROPERTY_CHANGED, arrayPropertyName);
            }
            return true;
        }
    });

}
