import { ServiceBase } from "../Frax";

function calculateElapsedSeconds(since, timeStamp) {
    if (timeStamp<since) return 0
    const now = timeStamp || new Date()
    return Math.round((now.getTime() - since.getTime()) / 1000)
}


class Cronos extends ServiceBase {
    constructor(defaultValue) {
        super({
            tasks: [],
            selectedTaskId: undefined,
        })

        this.state = defaultValue || { tasks: [] }
    }

    clear() {
        this.tasks = []
        this.selectedTaskId = undefined
    }

    addTask(name, timestamp) {
        // let task = this.state.tasks.find((t) => t.name == name)
        // if (task!=undefined) {
        //     console.error('Task name is already used')
        //     return undefined 
        // }

        const newTask = {
            id: this.tasks.length + 1,  // reemplazar por guid
            name: name,
            running: false,
            log: [],
            order: (timestamp || new Date()).getTime()
        }
        this.tasks.push(newTask)
        return newTask.id
    }

    start(taskId, timeStamp) {
        let task = this.tasks.find((t) => t.id === taskId)
        if (task.running) return

        // this not allows simultaneos tasks
        // var tasks = this.tasks.filter(t => t.running && t.id !== taskId);
        // tasks.map(t => this.end(t.id, undefined, timeStamp))

        task.running = true
        task.runningSince = timeStamp || new Date()
        task.order = task.runningSince.getTime()
        this.emitPropertyChanged('tasks')
    }

    end(taskId, logInfo, timeStamp) {
        let task = this.tasks.find((t) => t.id === taskId)
        if (!task.running) return

        let logEntry = {
            startedOn: task.runningSince,
            endedOn: timeStamp || new Date(),
            info: logInfo,
        }

        logEntry.elapsedTime = calculateElapsedSeconds(logEntry.startedOn, logEntry.endedOn)
        task.log.push(logEntry)
        task.running = false
        this.emitPropertyChanged('tasks')
    }

    getTaskReport(taskId, timeStamp) {
        timeStamp = timeStamp || new Date()
        let week = getWeekNumber(timeStamp)
        let month = timeStamp.getMonth()
        let year = timeStamp.getFullYear()

        const task = this.tasks.find((t) => t.id === taskId)

        const log = task.log.filter(x => x.startedOn <= timeStamp)

        const currentElapsedTime = task.running ? calculateElapsedSeconds(task.runningSince, timeStamp) : 0

        function getTaskForThisMonth() {


            return log.filter(x => { return x.startedOn.getFullYear() === year && x.startedOn.getMonth() === month })
        }

        function getTaskForThisWeek() {


            return log.filter(x => { return x.startedOn.getFullYear() === year && getWeekNumber(x.startedOn) === week })
        }

        function getWeekNumber(d) {
            // Copy date so don't modify original
            d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
            // Set to nearest Thursday: current date + 4 - current day number
            // Make Sunday's day number 7
            d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
            // Get first day of year
            var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
            // Calculate full weeks to nearest Thursday
            var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);

            return weekNo;
        }


        return {
            id: task.id,
            name: task.name,
            running: task.running,
            elapsedTime: currentElapsedTime,
            totalTimeThisMonth: getTaskForThisMonth().reduce((total, item) => total + item.elapsedTime, 0),
            totalTimeThisWeek: getTaskForThisWeek().reduce((total, item) => total + item.elapsedTime, 0),
            totalTime: log.reduce((total, item) => total + item.elapsedTime, 0) + currentElapsedTime,
            log: log.map(item => ({
                startedOn: item.startedOn,
                endedOn: item.endedOn,
                elapsedTime: item.elapsedTime,
                info: item.info,
            }))
        }
    }

    getTasksStates(filter) {
        // const compare = (a, b) => {
        //     return a.order > b.order ? -1 : 1;
        // }

        return this.tasks
            .filter(x => { return !filter || x.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0 })
            //.sort(compare)
            .map(t => {
                const tr = this.getTaskReport(t.id)
                return {
                    id: tr.id,
                    name: tr.name,
                    running: tr.running,
                    elapsedTime: tr.elapsedTime,
                    totalTime: tr.totalTime,
                }
            })

    }
}


export default Cronos