
function padZero(number) {
    return (number < 10 ? '0' : '') + number
}

function secondsToHHHMMSS(seconds) {
    const hours = Math.floor(seconds / 3600)
    seconds = seconds % 3600
    const minutes = Math.floor(seconds / 60)
    seconds = seconds % 60
    return hours + ':' + padZero(minutes) + ':' + padZero(seconds)
}

function secondsToHHHMM(seconds) {
    const hours = Math.floor(seconds / 3600)
    seconds = seconds % 3600
    const minutes = Math.floor(seconds / 60)
    return hours + 'hs ' + padZero(minutes) + 'm'
}

/*
function groupBy(list, field){
    var result = {}

    list.forEach(x => {
        const k = x[field]
        if (!result[k]) {
            result[k] = []
        }
        result[k].push(x)
    });

    return result
}
*/


export default {
    padZero: padZero,
    secondsToHHHMM: secondsToHHHMM,
    secondsToHHHMMSS: secondsToHHHMMSS
}