import React from 'react';
import { default as FraxReactComponent } from '../Frax/ReactComponent'
import Common from '../Services/Common'
import TaskList from './TaskList'
import NewTaskForm from './NewTaskForm'
import timeSvg from '../img/time.svg'

class MainScreen extends FraxReactComponent {
    constructor(props) {
        super(props)
        this.state = { showFilter: false, filter: undefined, newTaskFormVisible: false }
        this.cronos = Common.cronos
    }

    filterTask(event) {
        let t = event.target.value
        if (t.length >= 0) {
            this.setState({ filter: t })
        }
    }

    showNewTaskForm() {
        this.setState({ newTaskFormVisible: true })
    }

    render() {
        return <div className="full-screen flex-column">
            {!this.state.showFilter &&
                <div className="header">
                    <div>
                        <img src={timeSvg} style={{ width: '30px', height: 'auto' }} alt="cronos" />
                    </div>
                    <div>TASKAR</div>
                    <button onClick={() => this.setState({ showFilter: true })} className="button button-filter" ></button>
                </div>
            }
            {this.state.showFilter &&
                <div className="header">
                    <div>
                        {/* <img src="img/time.svg" style={{ width: '30px', height: 'auto' }} alt="cronos" /> */}
                    </div>
                    <div>
                        <input style={{ width: '100%' }} onChange={(e) => this.filterTask(e)} className="flex-90" name="filter" type="text" />
                    </div>
                    <div>
                        <button onClick={() => this.setState({ showFilter: false, filter: undefined })} className="flex-10 button button-close" ></button>
                    </div>
                </div>
            }
            <TaskList service={this.cronos} filter={this.state.filter} className="flex padding"></TaskList>
            <button onClick={() => this.showNewTaskForm()} className="floating-button button-add" ></button>
            {this.state.newTaskFormVisible && 
                <NewTaskForm className="full-screen" 
                    onAccept={ newTaskName => { this.cronos.addTask(newTaskName); this.setState({newTaskFormVisible:false}) }}
                    onCancel={ ()=> this.setState({newTaskFormVisible:false}) }
                ></NewTaskForm>}

        </div>
    }
}

export default MainScreen;

