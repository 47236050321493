import React from 'react';
import { default as FraxReactComponent } from '../Frax/ReactComponent'

class NewTaskForm extends FraxReactComponent {

    submit(event) {
        event.preventDefault()
        var t = document.getElementById('name').value
        if (!t) {
            alert('The task name cannot be empty')
            return
        }
        this.props.onAccept(t)
        document.getElementById('name').value = ''
    }

    cancel(event){
        event.preventDefault()
        this.props.onCancel()
    }

    render() {

        return <div className={this.props.className}>
            <form className="flex-row" onSubmit={event => this.submit(event)}>
                <input className="flex-80" id="name" type="text" />
                <input className="flex-10" type="submit" value="Add" />
                <input className="flex-10" type="button" onClick={(e)=>this.cancel(e)} value="Cancel" />
            </form>
        </div>

    }
}


export default NewTaskForm;