import React from 'react';
import { ReactComponent as FraxReactComponent } from '../Frax'
import Util from '../Services/util'
//import cronos from '../Services/cronos'


class TaskView extends FraxReactComponent {


    groupLogByDate(log) {
        const groups = []
        log.forEach(x => {
            const date = x.startedOn.toISOString().substr(0, 10)
            let group = groups.find(g => g.key === date)
            if (!group) {
                group = { key: date, items: [] }
                groups.push(group)
            }
            group.items.push(x)
        });

        return groups
    }

    constructor(props) {
        super(props)
        this.taskId = props.taskId
        this.cronos = props.service
    }

    render() {
        const cronos = this.cronos
        const task = cronos.getTaskReport(this.taskId)

        const logByDate = this.groupLogByDate(task.log.sort((a, b) => a.startedOn > b.startedOn ? -1 : 1))

        return <div className="task-view full-screen flex-column">
            <div className="header">
                <div>
                    {!task.running && <button onClick={(e) => { e.preventDefault(); e.stopPropagation(); cronos.start(task.id); return false }} className="player-button play" ></button>}
                    {task.running && <button onClick={(e) => { e.preventDefault(); e.stopPropagation(); cronos.end(task.id, { description: 'ddd', type: 'programming' }); return false }} className="player-button pause" ></button>}
                </div>
                <div>Control</div>
                <button onClick={() => cronos.selectedTaskId = undefined} className="button button-close" ></button>
            </div>
            <div className="name padding">
                {task.name}
            </div>
            <div className="summary">
                <div className="summary-item">
                    <div className="summary-item-header">
                        This week
                </div>
                    <div className="summary-item-content">
                        {Util.secondsToHHHMM(task.totalTimeThisWeek)}
                    </div>
                </div>
                <div className="summary-item">
                    <div className="summary-item-header">
                        This month
                </div>
                    <div className="summary-item-content">
                        {Util.secondsToHHHMM(task.totalTimeThisMonth)}
                    </div>
                </div>
                <div className="summary-item">
                    <div className="summary-item-header">
                        Total
                </div>
                    <div className="summary-item-content">
                        {Util.secondsToHHHMM(task.totalTime)}
                    </div>
                </div>
            </div>
            <div className="detail">
                <ul>
                    {logByDate.map(g => <li key={g.key}>
                        <div>{g.key}</div>
                        <ul>
                            {g.items.map(x => <li className="detail-item" key={x.startedOn}>
                                <div className="type">{(x.info || {}).type}&nbsp;</div>
                                <div className="elapsed-time">{Util.secondsToHHHMM(x.elapsedTime)}</div>
                                <div className="description">{(x.info || {}).description}&nbsp;</div>
                                <div className="started-on">From: {x.startedOn.toISOString().substr(11, 5)}</div>
                                <div className="ended-on">To: {x.endedOn.toISOString().substr(11, 5)}</div>
                            </li>)}
                        </ul>

                    </li>)}
                </ul>
            </div>
        </div>
    }


}

export default TaskView;
