export default class Events {
    events = {};

    // createEvents() {
    //   Array.prototype.slice
    //     .call(arguments)
    //     .map(s => s.trim())
    //     .filter(s => s)
    //     .forEach(event => this.createEvent(event));
    // }

    // createEvent(event) {
    //   this["on" + event] = delegate => this.on(event, delegate);
    //   this["off" + event] = delegate => this.off(event, delegate);
    //   this["emit" + event] = (data, timestamp) =>
    //     this.emit(event, data, timestamp);
    //   return this;
    // }

    on(event, delegate) {
        if (!this.events[event]) this.events[event] = [];
        this.events[event].push(delegate);
        return { off: () => this.off(event, delegate) };
    }

    off(event, delegate) {
        this.events[event] = this.events[event].filter(d => d !== delegate);
    }

    emit(event, data, timestamp) {
        if (!this.events[event]) return;
        this.events[event].forEach(d => d.call(this, data, timestamp || undefined));
    }

    getSubscriber(event) {
        return delegate => this.on(event, delegate);
    }


    getEmiter(event) {
        return (data, timestamp) => this.emit(event, data, timestamp);
    }
}
