const [registry] = "registry"; //Symbol();

export default class Registry {
    [registry] = [];

    register(object) {
        this[registry].push(object);
        return {
            unregister: () => {
                this.unregister(object);
            }
        };
    }

    unregister(object) {
        if (object.off) object.off();
        this[registry] = this[registry].filter(obj => obj !== object);
    }

    clear() {
        this[registry].forEach(object => {
            if (object.off) object.off();
        });
        this[registry] = [];
    }
}
