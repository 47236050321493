import { Component } from "react";
import Registry from "./Registry";

const mountedEvents = Symbol();
export default class ReactComponent extends Component {
    [mountedEvents] = new Registry();

    refresh() {
        this.setState({})
    }

    mountEvent(event, delegate) {
        return this[mountedEvents].register(event(delegate));
    }

    unmountEvent(registryEntry) {
        return registryEntry.unregister();
    }

    unmountAllEvents() {
        this[mountedEvents].clear();
    }

    componentWillUnmount() {
        this.unmountAllEvents();
    }

    setInterval(delegate, time) {
        const intervalId = setInterval(delegate, time);
        var eventHandler = { off: () => clearInterval(intervalId) };
        return this[mountedEvents].register(eventHandler);
    }

    setTimeout(delegate, time) {
        let registryEntry;
        const timeoutId = setTimeout(() => {
            delegate();
            this.unmountEvent(registryEntry);
        }, time);
        registryEntry = this[mountedEvents].register({
            timeoutId: timeoutId,
            off: () => {
                clearTimeout(timeoutId);
            }
        });
        return registryEntry;
    }
}
