import React from 'react';
import { ReactComponent as FraxReactComponent } from '../Frax'
import Util from '../Services/util'


export default function TaskList(props) {
    const cronos = props.service
    const tasks = cronos.getTasksStates(props.filter)


    class TaskItem extends FraxReactComponent {
        constructor(props) {
            super(props)
            this.start()
        }
        start() {
            this.setInterval(() => {
                if (!cronos.getTaskReport(this.props.taskId).running) return

                this.refresh()
            }, 500)
        }
        render() {
            const x = cronos.getTaskReport(this.props.taskId)
            return (
                <div className="task-item">
                    <div className="name">{x.name}</div>
                    {x.running && <div className="elapsed running">{Util.secondsToHHHMMSS(x.elapsedTime)}</div>}
                    {x.running && <div className="total running">{Util.secondsToHHHMM(x.totalTime)}</div>}
                    {!x.running && <div className="total not-running">{Util.secondsToHHHMM(x.totalTime)}</div>}
                    {!x.running && <button onClick={(e) => { e.preventDefault(); e.stopPropagation(); cronos.start(x.id); return false }} className="player-button play" ></button>}
                    {x.running && <button onClick={(e) => { e.preventDefault(); e.stopPropagation(); cronos.end(x.id,{description:'ddd', type:'programming'}); return false }} className="player-button pause" ></button>}
                </div>)
        }
    }


    return <div className={(props.className || '') + ' task-list'}>
        <ul>
            {tasks.map(x =>
                <li key={x.id} onClick={() => { cronos.selectedTaskId = x.id }}>
                    <TaskItem taskId={x.id} />
                </li>
            )}
        </ul>
    </div>
}